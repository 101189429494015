import axios from "axios";

import {
    USERS_LOGIN_STARTED, 
    USERS_LOGIN_SUCCESS, 
    USERS_LOGIN_FAILURE,
    AUTH_STARTED, 
    AUTH_SUCCESS, 
    AUTH_FAILURE
 } from "./../types";


/******************************* *************************/
/************************* LOGIN *************************/
/******************************* *************************/

export const loginUser = ( {username, password} ) => dispatch => {
    dispatch(loginUserStarted());
    axios.defaults.withCredentials = true;

    axios
        .post("https://schilfkoerbchen.wilpy.de/api/v1/users/login", { username, password })
        .then(response => {
            if(response.data.status === "success") {
                dispatch(loginUserSuccess(response.data));
            }
            else {
                dispatch(loginUserFailure(response.data.message));
            }
        })
        .catch(error => {
            dispatch(loginUserFailure("Upps. Da ist etwas schief gegangen."));
        })
}

const loginUserStarted = () => ({
    type: USERS_LOGIN_STARTED
})

const loginUserSuccess = data => ({
    type: USERS_LOGIN_SUCCESS, 
    payload: {
        ...data
    }
})

const loginUserFailure = error => ({
    type: USERS_LOGIN_FAILURE, 
    payload: {
        error
    }
})



export const checkAuth = () => dispatch => {
    dispatch(authStarted());
    axios.defaults.withCredentials = true;

    axios
        .get("https://schilfkoerbchen.wilpy.de/api/v1/users/isAuth")
        .then(response => {
            if(response.data.status === "success") {
                console.log("sdfsdf");
                dispatch(authSuccess(response.data));
            }
            else {
                dispatch(authFailure());
            }
        })
        .catch(error => {
            dispatch(authFailure());
        })
}

const authStarted = () => ({
    type: AUTH_STARTED
})

const authSuccess = data => ({
    type: AUTH_SUCCESS, 
    payload: {
        ...data
    }
})

const authFailure = () => ({
    type: AUTH_FAILURE, 
    payload: {
        isAuth: false
    }
})