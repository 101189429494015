import React, { Component } from "react";

class Navbar extends Component {


  render() {
    return (
        <nav className="navbar navbar-light bg-light">
            <a className="navbar-brand" href="https://www.schilfkoerbchen.de/">zur Homepage</a>
        </nav>
    )
  }
}

export default Navbar;