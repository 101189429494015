import React, { Component } from "react";

import { BrowserRouter, Route } from "react-router-dom";

import Home from "./views/Home";
import Login from "./views/Login";
import ProtectedHome from "./views/ProtectedHome";

import MainLayout from "./hoc/mainLayout";
import Auth from "./hoc/auth";


class App extends Component {

  render() {
    return (
      <BrowserRouter> 
        <MainLayout>
          <Route path="/login" exact component={ Login } />
          <Route path="/protected" component={ Auth(ProtectedHome, true) } />
        </MainLayout>
      </BrowserRouter>
    );
  }
}

export default App;
