import axios from "axios";

import { USERS_LIST_STARTED, USERS_LIST_SUCCESS, USERS_LIST_FAILURE } from "./../types";

export const getUsersList = () => dispatch => {
    dispatch(getUsersListStarted());

      axios
        .get("http://localhost:3300/api/v1/users")
        .then(response => {
            console.log(response.data.data.users);
            dispatch(getUsersListSuccess(response.data.data.users));
        })
        .catch(error => {
            dispatch(getUsersListFailure(error));
        })
}

const getUsersListStarted = () => ({
    type: USERS_LIST_STARTED
})

const getUsersListSuccess = users => ({
    type: USERS_LIST_SUCCESS, 
    payload: {
        users
    }
})

const getUsersListFailure = error => ({
    type: USERS_LIST_FAILURE, 
    payload: {
        error
    }
})
