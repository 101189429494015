import { 
    USERS_LOGIN_STARTED, 
    USERS_LOGIN_SUCCESS, 
    USERS_LOGIN_FAILURE,
} from "../types";

const initialState = {
    loading: false,
    isAuth: false,
    token: null,
    error: null
}

export default (state = initialState, action) => {
    switch(action.type) {
            case USERS_LOGIN_STARTED:
                return {
                    ...state,
                    loading: true
                }
            case USERS_LOGIN_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    error: null,
                    isAuth: action.payload.isAuth
    
                };
            case USERS_LOGIN_FAILURE:
                return {
                    ...state,
                    loading: false,
                    error: action.payload.error,
                    isAuth: false
                };
        default:
            return state;
    }
}