import React, { Component } from "react";
import axios from "axios";

import LoginForm from "./../components/LoginForm";

class Home extends Component {

  state = {
    loading: true,
  }

  componentDidMount() {
    axios
    .get("https://schilfkoerbchen.wilpy.de/api/v1/users/isAuth", { withCredentials: true })
    .then(response => {
        if(response.data.isAuth) {
          this.props.history.push("/protected");
        }
        else {
          this.setState({loading: false});
        }
    })
    .catch(error => {
        this.setState({loading: false});
    })
  }

  render() {
    if(this.state.loading) {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    } else {
      return (
        <> 
          <LoginForm />
        </>
      );
    }
  }
}

export default Home;