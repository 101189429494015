import React, { Component } from "react";

import Overview from "../components/Overview";
import Logo from "./../data/logo.svg";

class ProtectedHome extends Component {



  render() {
    return (
      <> 
        <div className="text-center">
          <img className="logo text-center" src={Logo} alt="Logo" />
          <h3>Elternbereich | Übersicht</h3>
        </div>
        
        <Overview />
      </>
    );
  }
}



export default ProtectedHome;