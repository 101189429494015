import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { loginUser } from "./../../store/actions/user_actions";
import { Formik } from "formik";
import * as Yup from "yup";

const LoginSchema = Yup.object().shape({
  username: Yup.string().required("Bitte Nutzernamen eingeben"),
  password: Yup.string().required("Bitte Passwort eingeben")
})

class LoginForm extends Component {

  state = {
    success: false,
    validation: false
  }

  static getDerivedStateFromProps(props, state) {
    const auth = props.isAuth;
    return {
      success: auth ? true : false
    }
  }

  componentDidUpdate() {
    if(this.state.success) {
      this.props.history.push('/protected');
    }
  }

  render() {
    return (
      <div className="row justify-content-md-center"> 
        <Formik
          initialValues = {{ username: "", password: ""}}
          validationSchema = { LoginSchema }
          onSubmit = { values => {
            this.props.loginUser(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <form className="col-md-6 col-xl-4 loginForm" onSubmit={handleSubmit}>
              <h2>Login</h2>
              <div className="form-group">
                <input 
                  className={"form-control " + (errors.username && touched ? 'is-invalid' : '')}
                  type="text"
                  name="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  placeholder="Nutzername"
                />
                <div className="invalid-feedback">{ errors.username }</div>
              </div>
              <div className="form-group">
                <input 
                  className={"form-control " + (errors.password && touched ? 'is-invalid' : '')}
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  placeholder="Passwort"
                />
                <div className="invalid-feedback">{ errors.password }</div>
              </div>
              <button className="btn btn-outline-success" type="submit">Login</button>

              { this.props.loading 
                ? <div className="spinner-border text-success" role="status"><span className="sr-only">Loading...</span></div>
                : null
              }

              { this.props.error ? <div className="alert alert-danger" role="alert">{ this.props.error }</div> : null }
            </form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    ...state.login
  }
) 

const mapDispatchToProps = dispatch => (
  bindActionCreators({ loginUser }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm));