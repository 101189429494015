export const USERS_LIST_STARTED = "users_list_started";
export const USERS_LIST_SUCCESS = "user_list_success";
export const USERS_LIST_FAILURE = "user_list_failure";

export const USERS_LOGIN_STARTED = "users_login_started";
export const USERS_LOGIN_SUCCESS = "user_login_success";
export const USERS_LOGIN_FAILURE = "user_login_failure";

export const AUTH_STARTED = "auth_started";
export const AUTH_SUCCESS = "auth_success";
export const AUTH_FAILURE = "auth_failure";