import { combineReducers } from "redux";
import users from "./usersReducer";
import login from "./loginReducer";
import auth from "./authReducer";

const rootReducer = combineReducers({
    login,
    auth
});

export default rootReducer;