import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUsersList } from "./../../store/actions";


class UsersList extends Component {

 // componentDidMount() {
 //   this.props.getUsersList();
 // }

  render() {
    console.log(this.props);
    return (
      <div className="container text-center overview">
        <h5>Noch keine Beiträge vorhanden.</h5>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    ...state.users
  }
) 

const mapDispatchToProps = dispatch => (
  bindActionCreators({ getUsersList }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);