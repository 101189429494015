import React, { Component } from "react";
import axios from "axios";

export default function (ComposedClass, reload) {
    class AuthenticationCheck extends Component {

        state = {
            loading: true 
        }


        componentDidMount() {
            axios
            .get("https://schilfkoerbchen.wilpy.de/api/v1/users/isAuth", { withCredentials: true })
            .then(response => {
                console.log(response)
                if(response.data.isAuth) {
                    this.setState({loading: false});
                }
                else {
                    this.props.history.push("/login");
                }
            })
            .catch(error => {
                this.props.history.push("/login");
            })
        }


        render() {
            if(this.state.loading) {
                return (
                    <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )
            } else {
                return <ComposedClass {...this.props } />
            }  
        }
    }

    return AuthenticationCheck;

    
}