import { 
    AUTH_STARTED, 
    AUTH_SUCCESS, 
    AUTH_FAILURE
} from "../types";

const initialState = {
    loading: false,
    isAuth: false,
    token: null,
    error: null
}

export default (state = initialState, action) => {
    switch(action.type) {
            case AUTH_STARTED:
                    return {
                        ...state,
                        loading: true
                    };
                case AUTH_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        isAuth: action.payload.isAuth
                    };
                case AUTH_FAILURE:
                    return {
                        ...state,
                        loading: false,
                        isAuth: false
                    };
        default:
            return state;
    }
}